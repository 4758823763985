<template>
  <div class="flex-c a-center">
    <ul class="max-w1200 flex f-w col-333333">
      <li
        class="per-w25 ptb15 flex cup"
        v-for="(item, index) in store_list"
        :key="index"
        @click="detailsCut(item)"
      >
        <div class="w135h100 mr5">
          <img :src="item.img" alt="" class="w135h100" />
        </div>
        <div class="fs-12 col-333333">
          <div class="fs-14 ft-2 w130 l-h24">
            {{ item.title }}
          </div>
          <div>
            {{ item.class_name }}
          </div>
          <div>
            面积：<span class="col-FF0000">{{ item.area }}</span
            ><span class="col-FF0000" v-if="item.area_unit == 1">m²</span
            ><span class="col-FF0000" v-if="item.area_unit == 2">亩</span>
          </div>
          <div v-if="item.s_type == 1">
            租金：<span
              class="col-FF0000"
              v-if="item.rent_price == 0 || !item.rent_price"
              >面议</span
            ><span class="col-FF0000" v-else
              >{{ item.rent_price }}{{ item.money_unit }}/<span
                v-if="item.rent_time == 1"
                >年</span
              ><span v-if="item.rent_time == 2">月</span
              ><span v-if="item.rent_time == 3">平方</span></span
            >
          </div>
          <div v-if="item.s_type == 2 && item.type == 2">
            费用：<span class="col-FF0000" v-if="item.sell_type == 0"
              >面议</span
            >
            <span class="col-FF0000" v-if="item.sell_type == 1"
              >{{ item.sell_price }}{{ item.money_unit }}/总价</span
            >
            <span class="col-FF0000" v-if="item.sell_type == 2"
              >{{ item.sell_price }}{{ item.money_unit }}/平方</span
            >
          </div>
          <div v-if="item.type == 1 && item.s_type == 1">
            转让费：<span
              class="col-FF0000"
              v-if="!item.sell_price || item.sell_price == 0"
              >面议</span
            >
            <span v-else-if="item.sell_price == '-1'" class="col-FF0000"
              >无</span
            >
            <span class="col-FF0000" v-else>{{ item.sell_price }}万</span>
          </div>
        </div>
      </li>
    </ul>

    <div
      class="flex a-center j-center mb40"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
    index,
} from "../../utils/api.js"
export default {
  data() {
    return {
        s_type: "",
        id: "",
        page_nums:1,
        page:1,
        city:'',
        lat:'',
        lng:'',
        store_list: "",
    }
  },
  mounted(){
    this.lat = window.localStorage.getItem("lat");
    this.lng = window.localStorage.getItem("lng");
    this.city = window.localStorage.getItem('city');
    this.getdata();
  },
  methods: {
    // 跳转详情
    detailsCut(item) {
      console.log("跳转详情", item);
      if (item.s_type == 1 || item.s_type == 2) {
        // this.$router.push({
        //   path: "/transferdetails",
        //   query: { navid: 10, s_type: item.s_type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/transferdetails",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.s_type == 4) {
        // this.$router.push({
        //   path: "/Finddetails",
        //   query: { navid: 10, s_type: item.s_type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/Finddetails",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.s_type == 3 || item.s_type == 5) {
        let routeData = this.$router.resolve({
          path: "/SecondhandGoods",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    currentChange(e) {
      // console.log(e);
      this.page = e;
      this.getdata();
    },
    getdata() {
        // 已成交
        index({
            type: 4,
            page: this.page,
            city: this.city,
            lat: this.lat,
            lng: this.lng,
            })
            .then((respo) => {
                this.page_nums = respo.data.page_nums;
                this.store_list = respo.data.store_list; //已成交列表
                // console.log('bag',_this.bargain);
            })
            .catch((err) => {
                console.log(err);
            });
    },
  },
}
</script>

<style lang="scss" scoped></style>
